export const dimensions = {
  sidebarWidth: 220,
  sidebarCollapsedWidth: 60,
  sidebarTop: 90,
  sidebarCollapseButtonHeight: 24,
  heightAppBar: 120,
  heightFooter1: 84,
  heightFooter2: 56,
  heightSearchList: 360,
  actionsHeight: 40,
  headerFontSize: 12,
  treeNodeIndentationWidth: 15,
  treeNodeHeight: 32,
  generalPadding: 5,
  generalMargin: 5,
};
