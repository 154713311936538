import { getBrandColors, Brand } from "./brand";
import { dimensions, fontSizes, fontWeights } from "./settings";
import { flexHelpers } from "./helpers";
import { getMaterialTheme } from "./material";
import { themeContext } from "./theme.context";
import React from "react";

export const getTheme = (brand?: Brand) => {
  const { appColors, palette } = getBrandColors(brand);
  // any because materialTheme typing expecting everything
  // eslint-disable-next-line
  const materialTheme = getMaterialTheme({ palette } as any);
  return {
    ...materialTheme,
    // Override material default: show header above drawer (sidebar)
    zIndex: {
      ...materialTheme.zIndex,
      appBar: materialTheme.zIndex.drawer,
      drawer: materialTheme.zIndex.appBar,
    },
    appColors,
    dimensions,
    fontSizes,
    fontWeights,
    flexHelpers,
  };
};

export type Theme = ReturnType<typeof getTheme>;

export const getVCATheme = (brand?: Brand) => {
  const { appColors, palette } = getBrandColors(brand);
  // any because materialTheme typing expecting everything
  // eslint-disable-next-line
  const materialTheme = getMaterialTheme({ palette } as any);

  return {
    ...materialTheme,
    // Override material default: show header above drawer (sidebar)
    zIndex: {
      ...materialTheme.zIndex,
      appBar: materialTheme.zIndex.drawer,
      drawer: materialTheme.zIndex.appBar,
    },
    appColors,
    dimensions,
    fontSizes,
    fontWeights,
    flexHelpers,
  };
};

export type VCATheme = ReturnType<typeof getVCATheme>;

export const useThemeSwitcher = () => {
  const { viewMode, setViewMode } = React.useContext(themeContext);
  return { viewMode, setViewMode };
};
