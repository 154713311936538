import { colors as vcaColors } from "./brand/vca.colors";

export enum ViewModes {
  patronLight = "patronLight",
  patronDark = "patronDark",
  vca = "vca",
}

export type Brand =
  | ViewModes.patronLight
  | ViewModes.patronDark
  | ViewModes.vca
  | "XYZ"
  | undefined;

export const getBrandColors = (brand?: Brand) => {
  return vcaColors;
};
