import React, { useEffect, useState } from "react";
import { AppProps } from "next/app";
import { ApolloProvider } from "@apollo/client";
import { useApollo } from "../lib/gql/apollo/apollo";
import { ThemeProvider } from "@mui/material/styles";
import { CacheProvider } from "@emotion/react";
import { CssBaseline } from "@mui/material/";
import createCache from "@emotion/cache";
import TagManager from "react-gtm-module";

import { Brand, ViewModes } from "../lib/theme";
import { getVCATheme } from "../lib/theme/theme";
import Head from "next/head";
import AppContextProvider from "../utils/AppContextProvider";

export const cache = createCache({ key: "css", prepend: true });

const Application = ({ Component, pageProps }: AppProps) => {
  useEffect(() => {
    // removes the server-side injected styles
    const styles = document.querySelector("#jss-server-side");
    styles?.parentElement?.removeChild(styles);
    TagManager.initialize({ gtmId: "GTM-P576CHN" });
  }, []);

  const apolloClient = useApollo(pageProps.initialApolloState);
  const initialViewMode = ViewModes.vca;
  const [viewMode, setViewMode] = useState<Brand>(initialViewMode);

  return (
    <CacheProvider value={cache}>
      <Head>
        <title>VCA - Bestel hier je VCA-examen en VCA E-learning</title>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
      </Head>
      <ApolloProvider client={apolloClient}>
        <ThemeProvider theme={getVCATheme(viewMode)}>
          <CssBaseline />
          <AppContextProvider activeButtonID={1}>
            <Component {...pageProps} />
          </AppContextProvider>
        </ThemeProvider>
      </ApolloProvider>
    </CacheProvider>
  );
};

export default Application;
