import { AppColors, CustomPalette } from "./brand.model";

// don't export this.
const vcaColors = {
  primaryLight: "#07367D",
  primary: "#00346F",
  primaryDark: "#131F61",
  organizationColor: "#0A1761",
  personColor: "#C77700",
  secondaryLight: "#F36E22",
  secondary: "#FF6602",
  secondaryDark: "#E65C02",
  tertiaryLight: "#FF6602",
  tertiary: "#FFB201",
  tertiaryDark: "#E6A101",
  white: "#FFFFFF",
  paleGrey: "rgba(0, 0, 0, 0.6)",
  whitesmoke: "#F9F9F9",
  relationTag: "#C51162",
  primaryLighter: "#f1f7f8",
  error: "#d32f2f",
  contentHover: "rgba(63, 81, 181, 0.08)",
  actionableColor: "#303F9F",
  contentGrey: "#F2F2F2",
  lightGrey: "#7E7A7A",
  grey: "#807B79",
};

// colors used by all brands
const appColors: AppColors = {
  primary: vcaColors.primary,
  primaryDark: vcaColors.primaryDark,
  primaryLight: vcaColors.primaryLight,
  secondary: vcaColors.secondary,
  secondaryDark: vcaColors.secondaryDark,
  secondaryLight: vcaColors.secondaryLight,
  tertiary: vcaColors.tertiary,
  tertiaryDark: vcaColors.tertiaryDark,
  tertiaryLight: vcaColors.tertiaryLight,
  actionable: vcaColors.actionableColor,
  relationTag: vcaColors.relationTag,
  contentHover: vcaColors.contentHover,
  sidebar: vcaColors.white,
  error: vcaColors.error,
  topBar: vcaColors.primaryDark,
  white: vcaColors.white,
  bodyColorGrey: vcaColors.paleGrey,
  contentGrey: vcaColors.contentGrey,
  organization: vcaColors.organizationColor,
  person: vcaColors.personColor,
  contentBackground: {
    subtle: vcaColors.whitesmoke,
    default: vcaColors.whitesmoke,
    primary: vcaColors.primaryLighter,
  },
};

export const colors: CustomPalette = {
  appColors,
  organizationColor: vcaColors.organizationColor,
  personColor: vcaColors.personColor,
  palette: {
    //@ts-ignore
    type: "light",
    background: {
      default: vcaColors.whitesmoke,
      paper: vcaColors.white,
    },
    primary: {
      light: vcaColors.primaryLight,
      main: vcaColors.primary,
      dark: vcaColors.primaryDark,
    },
    secondary: {
      light: vcaColors.secondaryLight,
      main: vcaColors.secondary,
      dark: vcaColors.secondaryDark,
      contrastText: vcaColors.white,
    },
    tertiary: {
      light: vcaColors.tertiaryLight,
      main: vcaColors.tertiary,
      dark: vcaColors.tertiaryDark,
      contrastText: vcaColors.white,
    },
    info: {
      light: vcaColors.secondaryLight,
      main: vcaColors.secondary,
      dark: vcaColors.secondaryDark,
    },
  },
};
