/* eslint-disable func-style */
import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";

// import getConfig from "next/config";

// const { publicRuntimeConfig } = getConfig();

function createApolloClient() {
  return new ApolloClient({
    ssrMode: typeof window === "undefined",
    link: createHttpLink({
      uri: "/api/graphql", // e.g. https://www.myapi.com/api/v2
    }),
    cache: new InMemoryCache(),
  });
}

export default createApolloClient;
